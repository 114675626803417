import { createApp } from 'vue'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App.vue'
import router from './router'
import VueEasyLightbox from 'vue-easy-lightbox';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import i18n from './i18n';
import * as lightbox from 'lightbox2';
import "lightbox2/dist/css/lightbox.min.css";
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

library.add(faFacebookSquare)
library.add(faBars)

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(lightbox);
app.use(VueEasyLightbox); // global variable;
app.use(VuePlyr, {
    plyr: {}
  }); // global variable;
app.component('font-awesome-icon', FontAwesomeIcon);
app.config.globalProperties.translate = i18n.t;
app.mount('#app');
