import i18n from "@/i18n";
import { h, resolveComponent } from "@vue/runtime-core";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: `/${i18n.getLocale()}`
  },
  {
    path: "/:lang",
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: "/:lang/",
        name: "Home",
        component: Home,
      },
      {
        path: "/:lang/gallery",
        name: "Gallery",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Gallery.vue"),
      },
      {
        path: "/:lang/puppies",
        name: "Puppies",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Puppies.vue"),
      },
      {
        path: "/:lang/contact",
        name: "Contact",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
      },
    ],
  },
];

// const routes: Array<RouteRecordRaw> = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/gallery',
//     name: 'Gallery',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
//   },
//   {
//     path: '/puppies',
//     name: 'Puppies',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/Puppies.vue')
//   },
//   {
//     path: '/contact',
//     name: 'Contact',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
//   }
// ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  document.title = "Komorowianka";
  if (!language) {
    language = "pl";
  }
  console.log(typeof language, to.params);
  if (typeof language === "string") {
    i18n.setLocale(language);
  }

  // set the current language for vuex-i18n. note that translation data
  // for the language might need to be loaded first
  next();
});

export default router;
